import Axios from 'axios';

export const getToken = () => {
    Axios.get(
        `https://stage.abgapiservices.com/oauth/token/v1`,
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'client_id': '04e19f27',
                'client_secret': '4ed319e489d262258325686bf0902603'
            }
        }
    ).then((res) => {
        //  console.log(res.data.access_token);
        localStorage.setItem('bebe', res.data.access_token);
        return res.data.access_token;
    })
}

// export const formatRupiah = (number) => {
//     return new Intl.NumberFormat("id-ID", {
//         style: "currency",
//         currency: "IDR",
//         minimumFractionDigits: 0, // Set minimum fraction digits to 0 to remove decimals
//         maximumFractionDigits: 0,
//         currencyDisplay: "narrowSymbol",
//     }).format(number);
// }

export const formatRupiah = (number) => {
    // Convert number to string and split it by thousands separator
    const parts = parseInt(number).toFixed(0).toString().split(".");
    // Add IDR symbol to the beginning
    let formattedNumber = "IDR " + parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedNumber;
}

export const generateRandomNumbers =
    () => {
        var randomNumbers = [];
        for (var i = 0; i < 9; i++) {
            randomNumbers.push(Math.floor(Math.random() * 100)); // Adjust the range as needed
        }
        return randomNumbers;
    }

export const formatDateTglBulanJam = (inputDate) => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const date = new Date(inputDate);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const month = months[monthIndex];
    const hour = date.getHours();
    const minute = date.getMinutes();

    const formattedDate = `${day} ${month} ${hour < 10 ? '0' : ''}${hour}:${minute < 10 ? '0' : ''}${minute}`;

    return formattedDate;
}
