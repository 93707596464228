import React from "react";
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from "../common/header/HeaderOne";
import AP from "../components/avisapp/AP";

const AvisApp = () => {
    return (
        <>
            <HeaderOne />
            <AP />
            <FooterOne />
        </>
    )
}

export default AvisApp;