import React from "react";
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from "../common/header/HeaderOne";
import UL from "../components/uslocation/UL";

const USLocation = () => {
    return (
        <>
            <HeaderOne />
            <UL />
            <FooterOne />
        </>
    )
}

export default USLocation;