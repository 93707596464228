import React from "react";
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from "../common/header/HeaderOne";
import LD from "../components/lossdamage/LD";

const LossDamage = () => {
    return (
        <>
            <HeaderOne />
            <LD />
            <FooterOne />
        </>
    )
}

export default LossDamage;