import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MobileMenu from "./MobileMenu";
import SignModal from "./SignModal";
import ModalSubscribe from "./ModalSubscribe";

const Nav = (props) => {
  const [btnMobile, setBtnMobile] = useState(false);

  // useEffect(() => {
  //   props.setIsMenu(btnMobile);
  // }, [btnMobile]);
  let publicUrl = process.env.PUBLIC_URL + '/'
  return (
    <>
      <div className="main-menu__inner">
        <MobileMenu btnIsMobile={btnMobile} setIsMobile={setBtnMobile} />
        <ul className="main-menu__list myNav">
          <li className="dropdown">
            <Link to={process.env.PUBLIC_URL + `/`}>Reservations</Link>
            <ul>
              <li>
                <Link to={process.env.PUBLIC_URL + `/makereservation`}>
                  Make a Reservation
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + `/view-cancel`}>
                  View / Cancellation
                </Link>
              </li>
              <li>
                <a
                  href="https://www.avis.com/en/reservation/get-e-receipt"
                  target="_blank"
                  rel="noreferrer"
                >
                  Get E-Receipt
                </a>
              </li>
            </ul>
          </li>
          <li className="dropdown">
            <Link to={process.env.PUBLIC_URL + `/`}>Events and Specials</Link>
            <ul>
              <li>
                <Link to={process.env.PUBLIC_URL + `/promotion`}>
                  Promotion
                </Link>
              </li>
            </ul>
          </li>
          <li className="dropdown">
            <Link to={process.env.PUBLIC_URL + `#`}>Locations</Link>
            <ul>
              <li>
                <Link to={process.env.PUBLIC_URL + `/findlocation`}>
                  Find a Location
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + `/airportlocation`}>
                  Top Airport Locations
                </Link>
              </li>
            </ul>
          </li>
          <li className="dropdown">
            <Link to={process.env.PUBLIC_URL + `/`}>Cars & Services</Link>
            <ul>
              <li>
                <Link to={process.env.PUBLIC_URL + `/carguides`}>
                  Car Guides
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + `/productservice`}>
                  Product & Services
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + `/protection`}>
                  Protections & Coverage
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + `/program`}>Programs</Link>
              </li>
            </ul>
          </li>
          <li className="dropdown">
            <Link to={process.env.PUBLIC_URL + `#`}>Help</Link>
            <ul>
              <li>
                <Link to={process.env.PUBLIC_URL + `/customer-service`}>
                  Customer Service
                </Link>
              </li>
              <li>
                <a
                  href="https://faq.avis.co.uk/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Faqs
                </a>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + `/contact-us`}>
                  Contact Us
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + `/view-cancel`}>
                  Reservation/Cancellation
                </Link>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.avis.co.uk/TermsAndCondition"
                  rel="noreferrer"
                >
                  Rental Location Terms and Conditions
                </a>
              </li>
            </ul>
          </li>
          <li className="dropdown ps-2 border-start">
            <a
              href="https://member.avisworld.com/"
              target="_blank"
              rel="noreferrer"
            >
              Avis Preferred
            </a>
          </li>
          {localStorage.getItem('token') ? (
            <li className="dropdown"><a href={publicUrl + "userhome"}><span className="fa fa-user"></span> {localStorage.getItem('name')}</a></li>
          ) : (
            <><li className="dropdown">
              <Link to={process.env.PUBLIC_URL + `/register`}>Sign Up</Link>
            </li>
              <li className="dropdown pe-2 border-end">
                <Link to={process.env.PUBLIC_URL + `#`}><SignModal /></Link>
              </li></>
          )}
        </ul>
      </div>
    </>
  );
};

export default Nav;
