import React from "react";
import { Link } from "react-router-dom";

const ContentHistorical = () => {
  return (
    <div className="container pb-4">
      <h5 className="text-dark mb-2">View our list of events!</h5>
      <p className="mb-2">
        1946 - Avis is founded by Warren Avis at Willow Run Airport in Detroit
        with an $85,000 investment. This is the first car rental operation
        located at an airport. Soon after, facilities are opened at Miami
        Airport.
      </p>
      <p className="mb-2">
        1953 - Franchise operations are begun in Europe, Canada and Mexico.
      </p>
      <p className="mb-2">
        1954 - Avis is sold to Boston financier Richard S. Robie for $8 million.
      </p>
      <p className="mb-2">
        1956 - Avis is acquired by investment group led by Amoskeag Company.
        Avis, Inc. is formed as a formal holding company for the company's
        related business interests.
      </p>
      <p className="mb-2">
        1960 - Avis Europe corporate offices are established in the United
        Kingdom.
      </p>
      <p className="mb-2">
        1962 - Investment banking firm, Lazard Freres & Company, buys Avis.
      </p>
      <p className="mb-2">
        1963 - "We Try Harder." advertising and marketing campaign makes its
        debut.
      </p>
      <p className="mb-2">1965 - ITT Corporation buys Avis for $51 million.</p>
      <p className="mb-2">
        1972 - ITT spins off Avis as a public company. Wizard® is introduced,
        the first real-time information management and reservation system.
      </p>
      <p className="mb-2">
        1973 - Avis is recognized as the market leader in Europe, Africa and the
        Middle East.
      </p>
      <p className="mb-2">
        1977 - Norton Simon, Inc. buys Avis for $174 million.
      </p>
      <p className="mb-2">
        1979 - Avis enters a worldwide advertising and marketing agreement with
        General Motors and begins featuring GM cars in its fleet worldwide.
      </p>
      <p className="mb-2">
        1983 - Esmark, Inc. acquires Norton Simon in a $1 billion purchase; Avis
        is part of the deal.
      </p>
      <p className="mb-2">
        1984 - Beatrice buys Esmark for $2.5 billion, with Avis included again.
        Rapid Return is introduced - the car rental industry's first automated
        self-service check-in service.
      </p>
      <p className="mb-2">
        1985 - Wizard is implemented in Europe, directly linking over 2,000 Avis
        locations with the ability to interface with all major reservation
        systems.
      </p>
      <p className="mb-2">
        1986 - The investment firm, Kohlberg, Kravis, Roberts & Co. buys
        Beatrice for $6.2 billion; says it will sell Avis. Wesray Capital
        Corporation, another investment firm, acquires Avis for $263 million
        plus the assumption of debt. Shares of Avis Europe are listed on the
        London Stock Exchange.
      </p>
      <p className="mb-2">
        1987 - Avis' Employee Stock Ownership Plan (ESOP) purchases Avis for
        $750 million, plus the assumption of debt. Avis is considered a model
        ESOP company and is one of the largest employee-owned companies in the
        United States. WizCom International, Ltd., an Avis subsidiary, is
        established to market the company's renowned computer expertise to major
        hotel chains around the world and other industries, including other car
        rental companies. Avis also introduces Roving Rapid Return, a handheld
        computer terminal allowing customers to bypass the Avis counter.
      </p>
      <p className="mb-2">
        1989 - General Motors Corporation buys Avis stock; becomes a minority
        shareholder. Avis Europe is sold to Cilva Holdings, which is jointly
        owned by Lease International SA, General Motors Overseas Corporation and
        Avis, Inc.
      </p>
      <p className="mb-2">
        1989 - Avis Cares®, a multifaceted program designed to increase
        renter/employee-owner safety, is introduced.
      </p>
      <p className="mb-2">
        1991 - Avis earns site visit by Malcolm Baldrige National Quality
        examiners in competition for prestigious award.
      </p>
      <p className="mb-2">
        1992 - Avis earns second on-site visit by Baldrige examiners.
      </p>
      <p className="mb-2">
        1994 - Wizard is implemented in more than 34 countries.
      </p>
      <p className="mb-2">
        1996 - Avis is acquired by HFS Incorporated, the world's largest
        franchiser of hotels and residential real estate brokerage offices.
        Wizard is now in 53 countries.
      </p>
      <p className="mb-2">
        1997 - Avis becomes a public company with a successful initial public
        offering. HFS retains the Avis brand name and franchising rights, WizCom
        International, and a minority interest in the newly public Avis. Avis
        purchases all of the outstanding capital stock of First Gray Line, the
        second largest Avis System franchisee in North America, with 70
        locations in Southern California, Nevada and Arizona.
      </p>
      <p className="mb-2">
        1998 - Avis announces secondary offering of its common stock.
      </p>
      <p className="mb-2">
        1998 - Avis purchases Hayes Leasing Company, Inc., a franchisee in
        Texas, and also makes six other smaller acquisitions.
      </p>
      <p className="mb-2">
        1999 - Avis continues to purchase franchisees and acquires the fleet
        management and fuel card businesses of PHH and Wright Express to form
        Avis Group Holdings, Inc.
      </p>
      <p className="mb-2">
        2000 - Avis InterActive is introduced, the industry's first
        Internet-based reporting system.
      </p>
      <p className="mb-2">
        2001 - Avis is ranked highest for customer loyalty among 129 companies
        evaluated for the Brand Keys® Customer Loyalty Awards.
      </p>
      <p className="mb-2">
        2001 - Cendant purchases all of the outstanding shares of Avis Group
        Holdings it did not currently own in a transaction valued at $937
        million, resulting in Avis Rent A Car System, Inc. becoming a wholly
        owned subsidiary of Cendant. The company's world headquarters moves from
        Garden City, NY to Parsippany, NJ.
      </p>
      <p className="mb-2">
        2001 - Avis ties for number one in the J.D. Power ranking of business
        traveler satisfaction with rental car companies.
      </p>
      <p className="mb-2">
        2001 - AARP names Avis one of 11 "Exemplary Companies for Workers Over
        50"
      </p>
      <p className="mb-2">
        2002 - Avis becomes the first and only rental car company to adopt the
        National Highway Traffic Safety Administration's LATCH system ("Lower
        Anchors and Tethers for Children") as its standard for child restraint
        installation.
      </p>
      <p className="mb-2">
        2002 - Avis is ranked highest for customer loyalty among 158 companies
        evaluated for the Brand Keys® Customer Loyalty Awards.
      </p>
      <p className="mb-2">
        2003 - Canadian Professional Sales Association names Avis "Car Rental
        Company of the Year"
      </p>
      <p className="mb-2">
        2003 - Women's Presidents' Educational Organization (WPEO) presents Avis
        with "Outstanding Corporation of the Year" award.
      </p>
      <p className="mb-2">
        2003 - Women's Business Enterprise National Council (WBENC) names Avis
        "One of America's Elite Eight Corporations for Women's Business
        Enterprises" for the second consecutive year.
      </p>
      <p className="mb-2">
        2003 - Avis launches a Spanish and Portuguese language version of
        Avis.com.
      </p>
      <p className="mb-2">
        2003 - Avis introduces XM Satellite Radio in all Premium and Luxury
        vehicles.
      </p>
      <p className="mb-2">
        2003 - Avis Assist, the first hand-held GPS system is introduced.
      </p>
      <p className="mb-2">
        2004 - Avis launches Avis Access - the first car rental offerings for
        travelers with disabilities.
      </p>
      <p className="mb-2">
        2005 - Avis is ranked number one car rental company in customer loyalty
        by Brand Keys. Also ranked number two in overall customer loyalty behind
        Google.
      </p>
      <p className="mb-2">
        2005 - Avis launches the first wi-fi in car rental locations.
      </p>
      <p className="mb-2">
        2005 - Avis is ranked number one car rental company in customer loyalty
        by Brand Keys. Also ranked number two in overall customer loyalty behind
        Google.
      </p>
      <p className="mb-2">
        2005 - Avis is honored by AARP as a "Featured Employer" for employing
        and retaining employees over 50.
      </p>
      <p className="mb-2">
        2005 - Cendant announces it will split into four separate companies.
      </p>
      <p className="mb-2">2006 - Avis becomes Avis Rent A Car System, LLC.</p>
      <p className="mb-2">
        2006 -- Cendant Corp. is separated into four independent companies,
        Realogy, Wyndham Worldwide, Travelport and Avis Budget Group, Inc.,
        which is now the parent company of Avis Rent A Car System, LLC.
      </p>
      <p className="mb-2">
        2006 - Avis introduces 'Cool Cars', a collection of fun-to-drive
        vehicles that can be reserved by specific make and model
      </p>
      <p className="mb-2">
        2006 - Avis debuts <i>where2</i>®, a portable GPS navigation system, in
        the United States
      </p>
      <p className="mb-2">
        2006 - Avis becomes the first car rental company to offer electronic
        toll collection, by introducing <i>eToll</i> on the E-ZPass<sup>SM</sup>{" "}
        network.
      </p>
      <p className="mb-2">
        2007 - Avis is the first rental car company to offer customers portable
        wireless Internet with Avis Connect
      </p>
      <p className="mb-2">
        2007 -Avis introduces <i>e-Receipts</i>, a paperless alternative to
        rental receipts
      </p>
      <p className="mb-2">
        2007 - Avis rolls out <i>where2</i> GPS to Canada
      </p>
      <p className="mb-2">
        2007 - Avis launches <i>Avis First</i>, a new customer loyalty program
        for frequent renters
      </p>
      <p className="mb-2">
        2007 - Avis introduces "Chauffeur Drive," in conjunction with WeDriveU,
        Inc.
      </p>
      <p className="mb-2">
        2007 - Avis expands <i>eToll</i> to Florida, Colorado and Puerto Rico
      </p>
      <p className="mb-2">
        2007 - Another technology time-saving first from Avis: Avis.com is
        optimized for handheld devices
      </p>
      <p className="mb-2">
        2007 - To complement <i>where2</i> GPS, Avis introduces an online trip
        planner at www.avis.com/mywhere2. Renters can download information on
        concerts, sporting events and other activities at 25 major U.S. business
        travel destinations.
      </p>
      <p className="mb-2">
        2008 - Avis introduces next generation of <i>where2</i> GPS
      </p>
      <p className="mb-2">
        2008 - Avis adds the storied Chevrolet Corvette to its 'Cool Car'
        collection
      </p>
      <p className="mb-2">
        2008 - New Mobility products for travelers with disabilities are added
        to <i>Avis Access®</i> program. Full suite of mobility devices are now
        available nationwide.
      </p>
      <p className="mb-2">
        2009 - The new Avis.com rolls off the assembly line
      </p>
      <p className="mb-2">
        2009 - Avis upgrades where2 GPS device with flight status feature
        provided by MSN® Direct
      </p>
      <p className="mb-2">
        2009 - Avis 'Cool Car' collection welcomes legendary Chevrolet Camaro to
        the streets
      </p>
      <p className="mb-2">
        2009 - Avis launches an iPhone application making it the United States'
        first-ever rental car app
      </p>
      <p className="mb-2">
        2010 - Avis is named the leading car rental company in customer loyalty
        by the Brand Keys® Customer Loyalty Engagement Index for the 11
        <sup>th</sup> consecutive year.
      </p>
      <p className="mb-2">
        2010 - Avis launches next-generation iPhone and iPod Touch application
      </p>
      <p className="mb-2">
        2011 - Avis Budget Group, Inc. acquires Avis Europe plc. The transaction
        re-unites Avis Budget Group with its licensee for Europe, the Middle
        East, Africa and Asia.
      </p>
      <Link className="btn btn-avis mt-4 d-inline-block">
        Make a Reservation
      </Link>
    </div>
  );
};

export default ContentHistorical;
