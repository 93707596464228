import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import WOW from "wowjs";
import { FaAngleUp } from "react-icons/fa";

import Title from "./Title";

//Pages
import HomeDefault from "./pages/HomeDefault";

import Register from "./pages/Register";

import BusinessRentals from "./pages/BusinessRentals";
import Affiliate from "./pages/Affiliate";
import BookingList from "./pages/BookingList";

import CustomerCare from "./pages/CustomerCare";

import MakeReservation from "./pages/MakeReservation";
import ViewModifyCancel from "./pages/ViewModifyCancel";
import GetReceipt from "./pages/GetReceipt";
import TripitService from "./pages/TripitService";
import BudgetPreCheck from "./pages/BudgetPreCheck";

import BookingDetail from "./pages/BookingDetail";

import ChooseCars from "./pages/ChooseCars";
import Extra from "./pages/Extra";
import ReviewAndBook from "./pages/ReviewAndBook";

import Promotion from "./pages/Promotion";
import Deals from "./pages/Deals";
import International from "./pages/International";
import PartnerOffers from "./pages/PartnerOffers";
import Avis360 from "./pages/Avis360";

import Avis360Detail from "./pages/Avis360Detail";

import AvisApp from "./pages/AvisApp";
import CarGuides from "./pages/CarGuides";
import AvisSignature from "./pages/AvisSignature";
import PopularRental from "./pages/PopularRental";
import ProductService from "./pages/ProductService";
import Services from "./pages/Services";
import Program from "./pages/Program";
import Protection from "./pages/Protection";
import LongTermRental from "./pages/LongTermRental";
import MeetingGroup from "./pages/MeetingGroup";

import MilePointAffiliate from "./pages/MilePointAffiliate";

import FastBreak from "./pages/FastBreak";

import FindLocation from "./pages/FindLocation";
import AirportLocation from "./pages/AirportLocation";
import USLocation from "./pages/USLocation";
import GlobalLocation from "./pages/GlobalLocation";
import TripIdeas from "./pages/TripIdeas";

import HomeTwo from "./pages/HomeTwo";
import HomeThree from "./pages/HomeThree";
import About from "./pages/About";
import ListingsOne from "./pages/ListingsOne";
import ListingsTwo from "./pages/ListingsTwo";
import ListingsThree from "./pages/ListingsThree";
import ListingsDetails from "./pages/ListingsDetails";
import Categories from "./pages/Categories";
import Visited from "./pages/Visited";
import Team from "./pages/Team";
import TeamDetails from "./pages/TeamDetails";
import Blog from "./pages/Blog";
import BlogDetails from "./pages/BlogDetails";
import Contact from "./pages/Contact";
import Error from "./pages/Error";
import ScrollToTopRoute from "./components/scroll-to-top-route/ScrollToTopRoute";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import LuxuryCar from "./pages/LuxuryCar";
import RentalCarSizes from "./pages/RentalCarSizes";
import PickupTruck from "./pages/PickupTruck";
import VanCar from "./pages/VanCar";
import SuvCar from "./pages/SuvCar";
import CarExploreDetails from "./pages/CarExploreDetails";
import ContactUs from "./pages/ContactUs";
import RoadsideAssitance from "./pages/RoadsideAssitance";
import RentalCarFuelPlans from "./pages/RentalCarFuelPlans";
import RentalCarFuelOptions from "./pages/RentalCarFuelOptions";
import SiteMap from "./pages/SiteMap";
import FraudScams from "./pages/FraudScams";

import UsFaqs from "./pages/UsFaqs";
import Tty from "./pages/Tty";

import AvisPreferred from "./pages/AvisPreferred";

import OneWayCarRental from "./pages/OneWayCarRental";
import FreeUpgrade from "./pages/FreeUpgrade";

import CarRentalLA from "./pages/CarRentalLA";
import CarRentalNYC from "./pages/CarRentalNYC";

import RentCarSize from "./pages/RentCarSize";
import LossDamage from "./pages/LossDamage";
import TravelAgent from "./pages/TravelAgent";
import Military from "./pages/Military";
import Signup from "./pages/Signup";
import TermsofUse from "./pages/TermsofUse";
import PrivacyNotice from "./pages/PrivacyNotice";
import CompanyInfo from "./pages/CompanyInfo";
import Mission from "./pages/Mission";
import CorporateFacts from "./pages/CorporateFacts";
import HistoricalChronology from "./pages/HistoricalChronology";
import AwardsRecognition from "./pages/AwardsRecognition";
import CorporateResponsibility from "./pages/CorporateResponsibility";
import RentalExecutiveTeam from "./pages/RentalExecutiveTeam";
import BestPriceGuarantee from "./pages/BestPriceGuarantee";
import TryHarder from "./pages/TryHarder";
import CopyrightNotice from "./pages/CopyrightNotice";
import BestPriceGuaranteeForm from "./pages/BestPriceGuaranteeForm";
import BlankPage from "./pages/BlankPage";
import AddiDriver from "./pages/AddiDriver";
import ChildSeats from "./pages/ChildSeats";
import ProductServiceUsa from "./pages/ProductServiceUsa";
import USGps from "./pages/USGps";
import USAviseToll from "./pages/USAviseToll";
import USFuelPlans from "./pages/USFuelPlans";
import DetailProtection from "./pages/DetailProtection";
import UserHome from "./pages/UserHome";
import ConfirmBooking from "./pages/ConfirmBooking";
import "react-datepicker/dist/react-datepicker.css";
import ViewBooking from "./pages/ViewBooking";

function App() {
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);
  return (
    <Router>
      <ScrollToTopRoute />
      <Routes>
        <Route
          path={`${process.env.PUBLIC_URL}/`}
          exact
          element={<HomeDefault />}
        />
        {/* Register */}
        <Route
          path={`${process.env.PUBLIC_URL}/avispreferred`}
          exact
          element={<AvisPreferred />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/register`}
          exact
          element={<Signup />}
        />
        {/* End Register */}
        {/* Businees Rentals */}
        <Route
          path={`${process.env.PUBLIC_URL}/businessrentals`}
          exact
          element={<BusinessRentals />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/affiliate`}
          exact
          element={<Affiliate />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/booking/confirm`}
          exact
          element={<ConfirmBooking />}
        />
        {/* End Businees Rentals */}
        {/* Customer Care */}
        <Route
          path={`${process.env.PUBLIC_URL}/customercare`}
          exact
          element={<CustomerCare />}
        />
        {/* End Customer Care */}
        {/* Reservation */}
        <Route
          path={`${process.env.PUBLIC_URL}/makereservation`}
          exact
          element={<MakeReservation />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/choosecars`}
          exact
          element={<ChooseCars />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/extra`}
          exact
          element={<Extra />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/reviewandbook`}
          exact
          element={<ReviewAndBook />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/bookingdetail`}
          exact
          element={<BookingDetail />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/bookinglist`}
          exact
          element={<BookingList />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/view_booking`}
          exact
          element={<ViewBooking />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/view-cancel`}
          exact
          element={<ViewModifyCancel />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/getreceipt`}
          exact
          element={<GetReceipt />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/tripitservice`}
          exact
          element={<TripitService />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/budgetprecheck`}
          exact
          element={<BudgetPreCheck />}
        />
        {/* End Reservation */}
        {/* Deals */}
        <Route
          path={`${process.env.PUBLIC_URL}/promotion`}
          exact
          element={<Promotion />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/promotion/deals`}
          exact
          element={<Deals />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/promotion/international`}
          exact
          element={<International />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/promotion/partner-offers`}
          exact
          element={<PartnerOffers />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/promotion/avis360`}
          exact
          element={<Avis360 />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/promotion/avis360detail`}
          exact
          element={<Avis360Detail />}
        />
        {/* End Deals */}
        {/* Cars & Services */}
        <Route
          path={`${process.env.PUBLIC_URL}/avisapp`}
          exact
          element={<AvisApp />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/carguides`}
          exact
          element={<CarGuides />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/avissignature`}
          exact
          element={<AvisSignature />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/popularrental`}
          exact
          element={<PopularRental />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/productservice`}
          exact
          element={<ProductService />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/services`}
          exact
          element={<Services />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/program`}
          exact
          element={<Program />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/protection`}
          exact
          element={<Protection />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/longtermrental`}
          exact
          element={<LongTermRental />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/meetinggroup`}
          exact
          element={<MeetingGroup />}
        />

        <Route
          path={`${process.env.PUBLIC_URL}/program/milepointaffiliate`}
          exact
          element={<MilePointAffiliate />}
        />
        {/* End Cars & Services */}
        {/* Help */}
        <Route
          path={`${process.env.PUBLIC_URL}/usfaqs`}
          exact
          element={<UsFaqs />}
        />
        <Route path={`${process.env.PUBLIC_URL}/tty`} exact element={<Tty />} />
        {/* End Help */}
        {/* Fastbreak */}
        <Route
          path={`${process.env.PUBLIC_URL}/fastbreak`}
          exact
          element={<FastBreak />}
        />
        {/* End Fastbreak */}
        {/* Locations */}
        <Route
          path={`${process.env.PUBLIC_URL}/findlocation`}
          exact
          element={<FindLocation />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/airportlocation`}
          exact
          element={<AirportLocation />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/uslocation`}
          exact
          element={<USLocation />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/globallocation`}
          exact
          element={<GlobalLocation />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/tripideas`}
          exact
          element={<TripIdeas />}
        />
        {/* End Locations */}
        {/* Popular Searches */}
        <Route
          path={`${process.env.PUBLIC_URL}/productservice/onewaycarrental`}
          exact
          element={<OneWayCarRental />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/freeupgrade`}
          exact
          element={<FreeUpgrade />}
        />
        {/* End Popular Searches */}
        {/* Popular US Destination */}
        <Route
          path={`${process.env.PUBLIC_URL}/carrentalla`}
          exact
          element={<CarRentalLA />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/carrentalnyc`}
          exact
          element={<CarRentalNYC />}
        />
        {/* End US Destination */}
        <Route
          path={`${process.env.PUBLIC_URL}/home-two`}
          exact
          element={<HomeTwo />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/home-three`}
          exact
          element={<HomeThree />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/about`}
          exact
          element={<About />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/listings1`}
          exact
          element={<ListingsOne />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/listings2`}
          exact
          element={<ListingsTwo />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/listings3`}
          exact
          element={<ListingsThree />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/listings-details`}
          exact
          element={<ListingsDetails />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/categories`}
          exact
          element={<Categories />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/visited`}
          exact
          element={<Visited />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/team`}
          exact
          element={<Team />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/team-details`}
          exact
          element={<TeamDetails />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/blog`}
          exact
          element={<Blog />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/blog-details`}
          exact
          element={<BlogDetails />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/contact`}
          exact
          element={<Contact />}
        />
        <Route path={`${process.env.PUBLIC_URL}/*`} exact element={<Error />} />
        {/* luxury Car Rental */}
        <Route
          path={`${process.env.PUBLIC_URL}/rentcarsize`}
          exact
          element={<RentCarSize />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/lossdamage`}
          exact
          element={<LossDamage />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/travelagent`}
          exact
          element={<TravelAgent />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/military`}
          exact
          element={<Military />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/luxury-car-rental`}
          exact
          element={<LuxuryCar />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/car-explore`}
          exact
          element={<CarExploreDetails />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/rent-car-sizes`}
          exact
          element={<RentalCarSizes />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/pickup-truck-rent`}
          exact
          element={<PickupTruck />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/suv-rental`}
          exact
          element={<SuvCar />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/van-rental`}
          exact
          element={<VanCar />}
        />
        {/* Company Info */}
        <Route
          path={`${process.env.PUBLIC_URL}/about-avis`}
          exact
          element={<About />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/about-avis/company-information/avis-mission-and-values`}
          exact
          element={<Mission />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/about-avis/company-information/corporate-facts`}
          exact
          element={<CorporateFacts />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/about-avis/company-information/historical-chronology`}
          exact
          element={<HistoricalChronology />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/about-avis/company-information/awards-and-recognition`}
          exact
          element={<AwardsRecognition />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/about-avis/company-information/corporate-responsibility`}
          exact
          element={<CorporateResponsibility />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/about-avis/company-information/executive-bios`}
          exact
          element={<RentalExecutiveTeam />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/products-and-services/services/best-rate-guarantee`}
          exact
          element={<BestPriceGuarantee />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/products-and-services/services/best-rate-guarantee/best-rate-claim-form`}
          exact
          element={<BestPriceGuaranteeForm />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/about-avis/we-try-harder`}
          exact
          element={<TryHarder />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/copyright-notice`}
          exact
          element={<CopyrightNotice />}
        />
        {/* End Company Info */}
        {/* Security */}
        <Route
          path={`${process.env.PUBLIC_URL}/terms-of-use`}
          exact
          element={<TermsofUse />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/privacy-notice`}
          element={<PrivacyNotice />}
        />
        {/* End Security*/}
        {/* Budget Support */}
        <Route
          path={`${process.env.PUBLIC_URL}/contact-us`}
          exact
          element={<ContactUs />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/roadside-assistance`}
          exact
          element={<RoadsideAssitance />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/productservice/fuel-plan`}
          exact
          element={<RentalCarFuelPlans />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/productservice/fuel-option`}
          exact
          element={<RentalCarFuelOptions />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/sitemap`}
          exact
          element={<SiteMap />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/customer-service`}
          exact
          element={<FraudScams />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/blankpage`}
          exact
          element={<BlankPage />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/productservice/additional-driver`}
          exact
          element={<AddiDriver />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/productservice/child-seats`}
          exact
          element={<ChildSeats />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/productservice/usa`}
          exact
          element={<ProductServiceUsa />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/productservice/usa/gps-navigation`}
          exact
          element={<USGps />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/productservice/usa/avis-e-toll`}
          exact
          element={<USAviseToll />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/productservice/usa/fuel-plans`}
          exact
          element={<USFuelPlans />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/protection/detail/:slug`}
          exact
          element={<DetailProtection />}
        />
        {/* User Home */}
        <Route
          path={`${process.env.PUBLIC_URL}/userhome`}
          exact
          element={<UserHome />}
        />
        {/* End User Home */}
      </Routes>
      <ScrollToTop
        className="scrollUp"
        smooth
        top="1500"
        component={<FaAngleUp />}
      />
    </Router>
  );
}

export default App;
