import React, { useEffect, useState } from "react";
import HeroOne from "../components/hero/HeroOne";
import ContentOne from "../components/index/ContentOne";
import ContentThree from "../components/index/ContentThree";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import Title from "../Title";
import ModalSubscribe from "../common/header/ModalSubscribe";
import { useNavigate } from "react-router-dom";

const HomeDefault = () => {
  Title("Avis Car Rental");
  const [showModal, setShowModal] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    const cookies = document.cookie.split(";");
    let subscribeCookieExists = false;
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith("subscribe=true")) {
        subscribeCookieExists = true;
        break;
      }
    }
    const tokenFrmLocalstorage = localStorage.getItem("token");
    if (!subscribeCookieExists && !tokenFrmLocalstorage) {
      setShowModal(true);
    }
  }, []);
  return (
    <>
      {showModal && <ModalSubscribe />}
      <HeaderOne optionHeader={true} scroll={true} />
      <HeroOne navigate={navigate} />
      <ContentOne />
      {/* <ContentTwo /> */}
      <ContentThree />
      <FooterOne />
    </>
  );
};

export default HomeDefault;
