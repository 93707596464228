import React from "react";

const OfferDetails = () => {
  return (
    <div className="container">
      <h3>Offer Details:</h3>
      <p className="mt-3">
        Extended Roadside Assistance is an optional assistance service that
        provides coverage at a reasonable additional daily charge. In the
        unlikely event of operational difficulty or unexpected emergency, we'll
        bring you fast, reliable assistance — especially helpful when you're in
        an unfamiliar city.
      </p>
      <p className="mt-3">
        <b>Key Replacement</b> — Get replacement keys or locksmith assistance.
      </p>
      <p>
        <b>Lockout Service</b> — Gain entry when keys are locked inside the
        vehicle.
      </p>
      <p>
        <b>Jump-Start</b> — If your vehicle experiences battery failure, we'll
        provide a jump-start.
      </p>
      <p>
        <b>Flat Tire Assistance or Tire Replacement</b> — We'll replace a flat
        or damaged tire with the spare, or get the car towed to the nearest
        service facility. (Please note that the renter might incur the cost of
        the tire repair or replacement, depending upon the circumstances.)
      </p>
      <p className="mb-5">
        <b>Fuel Delivery</b> — If you run out of gas, we'll deliver 3 gallons to
        your location and bill you separately for the fuel.
      </p>
      <hr />
    </div>
  );
};

export default OfferDetails;
