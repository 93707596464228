import React, { useEffect, useState, Component } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { API_URL } from "../../core/constant";
import partnerData from "../../avis_partnerships.json"
import { generateRandomNumbers } from "../../core/functions";
import feesCountry from "../../avis_cancel_fees.json"

class RBN extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      countryPick: "Indonesia",
      addressPick: "",
      addressPick2: "",
      cityPick: "",
      stateProvincePick: "",
      zipCodePick: "",
      cardType: "",
      cardNumber: "",
      expirationMonth: "",
      expirationYear: "",
      isPromo: false,
      isLoading: true,
    };
  }

  formatDate(va) {
    const options = { month: "long", day: "numeric", year: "numeric" };
    const date = new Date(va);
    return date.toLocaleDateString(undefined, options);
  }

  generateRandomNumbers() {
    var randomNumbers = [];
    for (var i = 0; i < 9; i++) {
      randomNumbers.push(Math.floor(Math.random() * 100)); // Adjust the range as needed
    }
    return randomNumbers;
  }

  async gotoBook(e) {
    e.preventDefault();
    this.setState({ isLoading: true });
    var bookingNumber = "";
    localStorage.setItem("brand", "Avis");
    localStorage.setItem(
      "transaction_id",
      this.generateRandomNumbers().toString()
    );
    localStorage.setItem("pickup_date", this.props.before.date);
    localStorage.setItem("pickup_time", this.props.before.time);
    localStorage.setItem("pickup_location", this.props.before.location);
    localStorage.setItem("dropoff_date", this.props.before.return_date);
    localStorage.setItem(
      "dropoff_time",
      this.props.before.return_time.split(" ")[0]
    );
    localStorage.setItem("dropoff_location", this.props.before.return_location);
    localStorage.setItem(
      "vehicle_sipp_code",
      this.props.before.vehicle.category.sipp_code
    );
    localStorage.setItem(
      "rate_code",
      this.props.before.vehicle.rate_totals.rate.rate_code
    );
    localStorage.setItem(
      "amount",
      this.props.before.vehicle.rate_totals.pay_later.reservation_total
    );
    localStorage.setItem("first_name", this.state.firstName);
    localStorage.setItem("last_name", this.state.lastName);
    localStorage.setItem("telephone", this.state.phone);
    localStorage.setItem("email", this.state.email);
    localStorage.setItem("age", this.props.before.age);
    localStorage.setItem("countryPick", this.state.countryPick);
    localStorage.setItem("addressPick", this.state.addressPick);
    localStorage.setItem("addressPick2", this.state.addressPick2);
    localStorage.setItem("cityPick", this.state.cityPick);
    localStorage.setItem("stateProvincePick", this.state.stateProvincePick);
    localStorage.setItem("zipCodePick", this.state.zipCodePick);
    localStorage.setItem("isPromo", this.state.isPromo);

    var dateNow = new Date().toISOString().split("T")[0];

    // GK DIPAKE
    var tax = parseFloat(this.props.before.tax.split(" ")[1]) * 14000;
    var price = parseFloat(this.props.before.price.split(" ")[1]) * 14000;
    // GK DIPAKE

    var dateNow = new Date().toISOString().split("T")[0];

    await Axios.get(
      "https://api.fxratesapi.com/convert?from=" +
      this.props.before.vehicle.rate_totals.rate.currency +
      "&to=IDR&date=" +
      dateNow +
      "&amount=" +
      this.props.before.tax +
      "&format=json"
    )
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        tax = Math.round(data.result);
      });

    await Axios.get(
      "https://api.fxratesapi.com/convert?from=" +
      this.props.before.vehicle.rate_totals.rate.currency +
      "&to=IDR&date=" +
      dateNow +
      "&amount=" +
      this.props.before.price.split(" ")[1] +
      "&format=json"
    )
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        price = Math.round(data.result);
      });

    tax += ((tax + price) * (this.props.before.taxValue == 0 ? 0 : this.props.adminTax)) / 100;
    var total_pay = tax + price;
    console.log("TOTAL: ", total_pay);

    if (this.props.before.paymentType == 'paylaters') {
      let pickupDate = new Date(localStorage.getItem('pickup_date'));
      let dropoffDate = new Date(localStorage.getItem('dropoff_date'));

      let pickupDateYear = pickupDate.getFullYear();
      let pickupDatemonth = (pickupDate.getMonth() + 1).toString().padStart(2, '0');
      let pickupDateday = pickupDate.getDate().toString().padStart(2, '0');

      let dropoffDateYear = dropoffDate.getFullYear();
      let dropoffDatemonth = (dropoffDate.getMonth() + 1).toString().padStart(2, '0');
      let dropoffDateday = dropoffDate.getDate().toString().padStart(2, '0');

      pickupDate = pickupDateYear + "-" + pickupDatemonth + "-" + pickupDateday;
      dropoffDate = dropoffDateYear + "-" + dropoffDatemonth + "-" + dropoffDateday;

      var dataReserve = {
        "product": {
          "brand": "Avis",
          "iata_number": this.props.before.paymentType == "paylaters" ? "0113094R" : "0113105R",
        },
        "transaction": {
          "transaction_id": generateRandomNumbers().toString()
        },
        "reservation": {
          "email_notification": true,
          "pickup_date": pickupDate + "T" + localStorage.getItem('pickup_time').split(" ")[0] + ":00",
          "pickup_location": localStorage.getItem('pickup_location'),
          "dropoff_date": dropoffDate + "T" + localStorage.getItem('dropoff_time') + ":00",
          "dropoff_location": localStorage.getItem('dropoff_location'),
          "vehicle_sipp_code": localStorage.getItem('vehicle_sipp_code'),
        },
        "rate_totals": {
          "rate": {
            "rate_code": localStorage.getItem('rate_code'),
            "country_code": "ID"
          }
        },
        "customer": {
          "contact": {
            "first_name": localStorage.getItem('first_name'),
            "last_name": localStorage.getItem('last_name'),
            "telephone": localStorage.getItem('telephone').toString(),
            "email": localStorage.getItem('email'),
            "age": parseInt(localStorage.getItem('age')),
          },
          "address": {
            "country_code": "ID",
            "address_line_1": localStorage.getItem('addressPick') == null ? "" : localStorage.getItem('addressPick'),
            "address_line_2": localStorage.getItem('addressPick2') == null ? "" : localStorage.getItem('addressPick2'),
            "address_line_3": "",
            "city": localStorage.getItem('cityPick') == null ? "" : localStorage.getItem('cityPick'),
            "postal_code": localStorage.getItem('postalPick') == null ? "" : localStorage.getItem('postalPick'),
          }
        },
        // "insurance": localStorage.getItem('insurance'),
        "payment_preference": {
          "type": "CentralBillingAccount",
          "id": generateRandomNumbers().toString(),
          "fixed_value": "FixedValue",
          "electronic_indicator": true,
          "amount": parseInt(localStorage.getItem('amount')),
          "currency": "USD"
        }
      }

      await Axios.post(
        'https://stage.abgapiservices.com/cars/reservation/v2',
        dataReserve,
        {
          headers: {
            'Accept': '*/*',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('bebe'),
            'client_id': '04e19f27'
          }
        },
      )
        .then(function (response) {
          // handle success
          // console.log(response.data);
          return response.data;
        })
        .then(function (data) {
          // handle success
          console.log("DATA RESPONSE: ");
          bookingNumber = data.reservation.confirmation.number;
        })
        .catch(function (error) {
          // handle error
          // alert("No cars available for your search criteria.");
          if (error.response.status == 401) {
            window.location.reload();
          } else if (error.response.status == 400) {
            // alert(error.response.data.status.errors[0].details);
          }
        })
    }

    Axios.post(
      `${API_URL}/api/booking/save`,
      // `${API_URL}/api/booking/save`,
      {
        productType: "Avis",
        location: this.props.before.location,
        paymentType: this.props.before.paymentType,
        bookingNumber: bookingNumber,
        cardType: this.state.cardType,
        cardNumber: this.state.cardNumber,
        expirationMonth: this.state.expirationMonth,
        expirationYear: this.state.expirationYear,
        date: this.props.before.date,
        isPromo: this.state.isPromo,
        time: this.props.before.time,
        return_location: this.props.before.return_location,
        return_date: this.props.before.return_date,
        return_time: this.props.before.return_time.split(" ")[0],
        age: this.props.before.age,
        emailForm: this.state.email,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        country: this.props.before.country,
        customer_id: localStorage.getItem("customer_id"),
        countryCodePick: localStorage.getItem("countryCodePick"),
        offer_code: this.props.before.offer_code,
        car_id: this.props.before.vehicle,
        // extra: this.props.before.extra,
        total: Math.round(total_pay),
        car_img: this.props.before.img,
        car_name: this.props.before.name,
        car_model: this.props.before.model,
        car_make: this.props.before.make,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
      .then((res) => {
        console.log(res.data.status);
        this.setState({ isLoading: false });
        if (res.data.status == false) {
          alert(res.data.msg);
        } else {
          localStorage.setItem("rent_id", res.data.booking_code);
          this.props.navigate("/bookingdetail", {
            state: {
              booking: res.data.booking_code,
              price: price,
              tax: tax,
              total_pay: Math.round(total_pay),
              paymentType: this.props.before.paymentType,
              date: this.props.before.date,
              time: this.props.before.time,
              taxValue: this.props.before.taxValue,
              return_date: this.props.before.return_date,
              return_time: this.props.before.return_time,
              location: this.props.before.location,
              return_location: this.props.before.return_location,
              vehicle: this.props.before.vehicle,
              bookingNumber: bookingNumber,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
      });
    // post api create reservation
  }

  render() {
    let fees = feesCountry.find((val) => val["Country Code"] == localStorage.getItem('countryPick'));
    fees = fees == undefined ? "IDR 300.000" : '$' + fees["AVIS WEBSITE"];
    let publicUrl = process.env.PUBLIC_URL + "/";
    const myLink = {
      color: "rgb(212, 0, 42)",
    };
    const myBorder = {
      borderBottom: "2px solid rgb(212, 0, 42)",
    };
    const myLinkDis = {
      color: "rgb(168, 168, 168)",
    };
    const myBorderDis = {
      borderBottom: "2px solid rgb(168, 168, 168)",
    };
    const btnLink = {
      backgroundColor: "rgb(212, 0, 42)",
      color: "rgb(255, 255, 255)",
      padding: "0px 50px",
      // height: "50px"
    };

    return (
      <>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-sm-3">
                <p style={myLinkDis}>1</p>
                <p style={myBorderDis}></p>
              </div>
              <div className="col-sm-3">
                <p style={myLinkDis}>2</p>
                <p style={myBorderDis}></p>
              </div>
              <div className="col-sm-3">
                <p style={myLinkDis}>3</p>
                <p style={myBorderDis}></p>
              </div>
              <div className="col-sm-3">
                <p style={myLink}>4 YOUR INFORMATION</p>
                <p style={myBorder}></p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row mt-4">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-6 border-bottom">
                    <div className="row">
                      <div className="col-sm-6">
                        <p style={myLink}>Pick-Up</p>
                        <p>
                          <b>{localStorage.getItem('nameLocation') !== "undefined" && localStorage.getItem('nameLocation') !== null ? localStorage.getItem('nameLocation') : ""}</b><br />
                          {localStorage.getItem('cityLocation') !== "undefined" && localStorage.getItem('cityLocation') !== null ? localStorage.getItem('cityLocation') + "," : ""}
                          {localStorage.getItem('codeLocation') !== "undefined" && localStorage.getItem('codeLocation') !== null ? localStorage.getItem('codeLocation') + "," : ""}<br />
                          {localStorage.getItem('addressLine1Location') !== "undefined" && localStorage.getItem('addressLine1Location') !== null ? localStorage.getItem('addressLine1Location') + "," : ""}
                          {localStorage.getItem('addressLine2Location') !== "undefined" && localStorage.getItem('addressLine2Location') !== null ? localStorage.getItem('addressLine2Location') + "," : ""}
                          {localStorage.getItem('postalCodeLocation') !== "undefined" && localStorage.getItem('postalCodeLocation') !== null ? localStorage.getItem('postalCodeLocation') + "," : ""}
                          {localStorage.getItem('countryCodePick') !== "undefined" && localStorage.getItem('countryCodePick') !== null ? localStorage.getItem('countryCodePick') : ""}<br />
                          <b>{localStorage.getItem('telephoneLocation') !== "undefined" && localStorage.getItem('telephoneLocation') !== null ? localStorage.getItem('telephoneLocation') : ""}</b><br />
                          <b>Location Code: </b> {localStorage.getItem('codeLocation') !== "undefined" && localStorage.getItem('codeLocation') !== null ? localStorage.getItem('codeLocation') : ""}
                        </p>
                        <p>
                          <b>
                            {this.props?.before?.time}{" "}
                            {this.formatDate(this.props?.before?.date)}
                          </b>
                        </p>
                      </div>
                      <div className="col-sm-6">
                        <p style={myLink}>Return</p>
                        <p>
                          <b>{localStorage.getItem('nameLocation') !== "undefined" && localStorage.getItem('nameLocation') !== null ? localStorage.getItem('nameLocation') : ""}</b><br />
                          {localStorage.getItem('cityLocation') !== "undefined" && localStorage.getItem('cityLocation') !== null ? localStorage.getItem('cityLocation') + "," : ""}
                          {localStorage.getItem('codeLocation') !== "undefined" && localStorage.getItem('codeLocation') !== null ? localStorage.getItem('codeLocation') + "," : ""}<br />
                          {localStorage.getItem('addressLine1Location') !== "undefined" && localStorage.getItem('addressLine1Location') !== null ? localStorage.getItem('addressLine1Location') + "," : ""}
                          {localStorage.getItem('addressLine2Location') !== "undefined" && localStorage.getItem('addressLine2Location') !== null ? localStorage.getItem('addressLine2Location') + "," : ""}
                          {localStorage.getItem('postalCodeLocation') !== "undefined" && localStorage.getItem('postalCodeLocation') !== null ? localStorage.getItem('postalCodeLocation') + "," : ""}
                          {localStorage.getItem('countryCodePick') !== "undefined" && localStorage.getItem('countryCodePick') !== null ? localStorage.getItem('countryCodePick') : ""}<br />
                          <b>{localStorage.getItem('telephoneLocation') !== "undefined" && localStorage.getItem('telephoneLocation') !== null ? localStorage.getItem('telephoneLocation') : ""}</b><br />
                          <b>Location Code: </b> {localStorage.getItem('codeLocation') !== "undefined" && localStorage.getItem('codeLocation') !== null ? localStorage.getItem('codeLocation') : ""}
                        </p>
                        <p>
                          <b>
                            {this.props?.before?.reutrn_time}{" "}
                            {this.formatDate(this.props?.before?.return_date)}
                          </b>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 border-start border-bottom">
                    <div className="row">
                      <div className="col-sm-6">
                        <p>Base Rate</p>
                        <p style={myLink}>Taxes & Fees</p>
                        <p>Estimated Total</p>
                        <p style={myLink}>See Rate Terms</p>
                      </div>
                      <div className="col-sm-6 text-end">
                        <p>{this.props?.before?.price}</p>
                        <p>{this.props?.before?.tax}</p>
                        <p>
                          <b>{this.props?.before?.totalPrice}</b>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row border-bottom">
                  <div className="col-sm-6">
                    <div className="row mt-2">
                      <div className="col-sm-3">
                        <img
                          src={this.props?.before?.vehicle?.category?.image_url}
                          className="img-fluid"
                        />
                      </div>
                      <div className="col-sm-9">
                        <p>
                          <h5 className="text-dark">
                            {this.props?.before?.vehicle?.category?.name}
                          </h5>
                        </p>
                        <p>
                          {this.props?.before?.vehicle?.category?.make}{" "}
                          {this.props?.before?.vehicle?.category?.model}
                        </p>
                        <p>
                          <img src="/assets/images/icon/user.png" />{" "}
                          {this.props?.before?.vehicle?.capacity?.seats}
                          <img
                            src="/assets/images/icon/suitcase.png"
                            className="ms-2"
                          />{" "}
                          {
                            this.props?.before?.vehicle?.capacity
                              ?.luggage_capacity?.large_suitcase
                          }
                          L
                          <img
                            src="/assets/images/icon/suitcase.png"
                            className="ms-2"
                          />{" "}
                          {
                            this.props?.before?.vehicle?.capacity
                              ?.luggage_capacity?.large_suitcase
                          }
                          S
                        </p>
                        <p>
                          {
                            this.props?.before?.vehicle?.category
                              ?.vehicle_transmission
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 border-start text-end text-success">
                    <p>Other Information</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <form onSubmit={(e) => this.gotoBook(e)}>
            <div className="container">
              <div className="row mt-4 justify-content-md-center">
                <div className="col-sm-8">
                  <p className="pb-3">
                    {/* A credit card is required to secure this reservation, your
                    card will not be charged now, however if you no longer
                    required the rental,  */}
                    <b>IMPORTANT</b>: Cancelling up to 48 hours prior to scheduled pickup time, You will receive a refund of your prepaid amount, minus a <b>{fees}</b> cancellation fee.
                  </p>
                  <div className="row">
                    <div className="col-sm-12">
                      <h5 className="text-dark">YOUR INFORMATION</h5>
                    </div>
                    <div className="col-sm-12">
                      <hr />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-3">
                      <label>First Name</label>
                    </div>
                    <div className="col-sm-8">
                      <input
                        required
                        type="text"
                        onChange={(e) =>
                          this.setState({ firstName: e.target.value })
                        }
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-3">
                      <label>Last Name</label>
                    </div>
                    <div className="col-sm-8">
                      <input
                        required
                        type="text"
                        onChange={(e) =>
                          this.setState({ lastName: e.target.value })
                        }
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-3">
                      <label>Email</label>
                    </div>
                    <div className="col-sm-8">
                      <input
                        required
                        type="text"
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                        className="form-control"
                      />
                    </div>
                    <div className="col-sm-11 d-flex justify-content-center">
                      <input
                        type="checkbox"
                        className="me-1"
                        id="isPromo"
                        onChange={(e) =>
                          this.setState({ isPromo: !this.state.isPromo })
                        }
                      />
                      <label for={"isPromo"}>
                        send me email promotions and offers
                      </label>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-3">
                      <label>Phone</label>
                    </div>
                    <div className="col-sm-8">
                      <input
                        onChange={(e) =>
                          this.setState({ phone: e.target.value })
                        }
                        required
                        type="number"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="row mb-5">
                    <div className="col-sm-12">
                      <p>
                        At time of Check-out you may be asked to provide a
                        second form of goverment identification.
                      </p>
                    </div>
                  </div>
                  {this.props.before.paymentType == "paylaters" ? (
                    <>
                      {/* <div className="row mb-3">
                        <div className="col-sm-12">
                          <h5 className="text-dark">Billing Information</h5>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-sm-12">
                          <p>
                            A credit card is required to secure this
                            reservation, your card will not be charged now,
                            however if you no longer required the rental, you
                            must cancel at least 48 hours prior to your
                            scheduled pick-up time or you'll be charged a $75
                            no-show fee.
                          </p>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-sm-3">
                          <label>Card Type</label>
                        </div>
                        <div className="col-sm-8">
                          <select className="form-control" onChange={(e) => this.setState({ cardType: e.target.value })}>
                            <option value={"Visa"}>Visa</option>
                            <option value={"Mastercard"}>Mastercard</option>
                          </select>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-sm-3">
                          <label>Card Number</label>
                        </div>
                        <div className="col-sm-8">
                          <input type="number" className="form-control" onChange={(e) => this.setState({ cardNumber: e.target.value })} />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-sm-3">
                          <label>Expiration Date</label>
                        </div>
                        <div className="col-sm-4">
                          <select className="form-control" onChange={(e) => this.setState({ expirationMonth: e.target.value })}>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                            <option value={11}>11</option>
                            <option value={12}>12</option>
                          </select>
                        </div>
                        <div className="col-sm-4">
                          <select className="form-control" onChange={(e) => this.setState({ expirationYear: e.target.value })}>
                            <option value={2024}>2024</option>
                            <option value={2025}>2025</option>
                            <option value={2026}>2026</option>
                            <option value={2027}>2027</option>
                            <option value={2028}>2028</option>
                            <option value={2029}>2029</option>
                            <option value={2030}>2030</option>
                            <option value={2031}>2031</option>
                            <option value={2032}>2032</option>
                            <option value={2033}>2033</option>
                            <option value={2034}>2034</option>
                            <option value={2035}>2035</option>
                          </select>
                        </div>
                      </div> */}
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="row">
                    <div className="col-sm-12">
                      <hr />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-3">
                      <label>Country</label>
                    </div>
                    <div className="col-sm-8">
                      <select className="form-control">
                        <option>Indonesia</option>
                      </select>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-3">
                      <label>Address Line 1</label>
                    </div>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) =>
                          this.setState({ addressPick: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-3">
                      <label>
                        Address Line 2 <small>(optional)</small>
                      </label>
                    </div>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) =>
                          this.setState({ addressPick2: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-3">
                      <label>City</label>
                    </div>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) =>
                          this.setState({ cityPick: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-3">
                      <label>State/Province</label>
                    </div>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) =>
                          this.setState({ stateProvincePick: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-3">
                      <label>Zip/Pastel Code</label>
                    </div>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) =>
                          this.setState({ zipCodePick: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-12">
                      <hr />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-12">
                      <h5 className="text-dark">TRAVEL INFORMATION</h5>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-12">
                      <p>
                        We recommend providing your Travel information below.
                        These details help us stay informed any changes in your
                        travel plans and allow our agents to better to prepare
                        for your arrival.
                      </p>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-3">
                      <label>Flight Info</label>
                    </div>
                    <div className="col-sm-4">
                      <input
                        className="form-control"
                        placeholder="Flight Number"
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-12">
                      <hr />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-12">
                      <h5 className="text-dark">
                        Frequent Travel Program for Miles/Points
                      </h5>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-12">
                      <p>
                        Rewards program points will be awarded on eligible
                        rentals.
                      </p>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-3">
                      <label>Rewards Program</label>
                    </div>
                    <div className="col-sm-4">
                      <select className="form-control">
                        <option>Program Partner Name</option>
                        {
                          partnerData.map((data, index) => {
                            return (
                              <option key={index} value={data['Partner Code']}>{data.Partner}</option>
                            )
                          })
                        }
                      </select>
                    </div>
                    <div className="col-sm-4">
                      <input
                        className="form-control"
                        placeholder="Member Number"
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-12">
                      <hr />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-12">
                      <h5 className="text-dark">TERMS & CONDITIONS</h5>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-8">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="defaultCheck2"
                          onChange={(e) => this.setState({ isLoading: !this.state.isLoading })}
                        />
                        <label className="form-check-label" for="defaultCheck2">
                          <b>
                            I have read and accept the{" "}
                            <a
                              target="_blank"
                              style={{ textDecoration: "underline" }}
                              href="https://www.avis.co.uk/TermsAndCondition"
                              rel="noreferrer"
                            >
                              <span>Location Policies</span> and{" "}
                              <span>Rental Terms and Conditions.</span>
                            </a>
                          </b>
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-4 text-end">
                      <button
                        className="btn py-2"
                        style={btnLink}
                        disabled={this.state.isLoading}
                        type="submit"
                      >
                        Reserve
                      </button>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-12">
                      <hr />
                    </div>
                  </div>
                  {/* <div className='row mb-3'>
                                    <div className='col-sm-3'>
                                        <label>Travel Agents Only</label>
                                    </div>
                                    <div className='col-sm-4'>
                                        <input className='form-control' placeholder='IATA/ARC' />
                                    </div>
                                    <div className='col-sm-4'>
                                        <input className='form-control' placeholder='UR MEM #' />
                                    </div>
                                </div> */}
                </div>
              </div>
            </div>
          </form>
        </section>
        {/* End Slogan One */}
      </>
    );
  }
}

function RB(props) {
  let navigate = useNavigate();
  const [service, setService] = useState([]);
  const [car, setCar] = useState([]);
  const [spec, setSpec] = useState([]);
  const [price, setPrice] = useState("");
  const [tax, setTax] = useState("");
  const [fee, setFee] = useState("");
  const [total, setTotal] = useState("");
  const [adminTax, setAdminTax] = useState(0);

  // Run Function when pages loaded
  useEffect(() => {
    Axios.get(
      `${API_URL}/api/booking/tax?productType=Avis`,
      {},
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
      },
    ).then((res) => {
      setAdminTax(res.data.tax.tax);
    });

    Axios.post(
      // `${API_URL}/api/booking/information`,
      `${API_URL}/api/booking/information`,
      {
        date: props?.before?.date,
        return_date: props?.before?.return_date,
        car: props?.before?.vehicle,
        extra: props?.before?.extra,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    ).then((res) => {
      setService(res.data.additional);
      setCar(res.data.car);
      setSpec(res.data.spec);
      setPrice(res.data.price);
      setTax(res.data.pajak);
      setFee(res.data.fee);
      setTotal(res.data.total);
    });
  }, []);
  return (
    <RBN
      {...props}
      navigate={navigate}
      service={service}
      car={car}
      price={price}
      tax={tax}
      adminTax={adminTax}
      fee={fee}
      total={total}
    />
  );
}

export default RB;
