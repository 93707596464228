import React from "react";
import DealsOne from "../components/deals/DealsOne";
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from "../common/header/HeaderOne";

const Deals = () => {
    return (
        <>
            <HeaderOne />
            <DealsOne />
            <FooterOne />
        </>
    )
}

export default Deals;